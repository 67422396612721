import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="header"></div>
      <div className="body">
        <div>
          <div className="col-md-6">
            <div className="box-plate">
              <form novalidate="" className="ng-untouched ng-invalid ng-dirty">
                <div className="header-options-buts">
                  <div className="usage-bar-buts">
                    <a href="" className="transition-button active">Bireysel</a>
                    <a href="" className="transition-button">Ticari</a>
                  </div>
                </div>
                <div className="form-group input has-error">
                  <input
                    autocomplete="off"
                    autocorrect="off"
                    autocapitalize="off"
                    spellcheck="false"
                    antixss=""
                    className="textbox-input input-pad ng-untouched ng-pristine ng-valid"
                    id="txtMusteriNo"
                    type="text"
                    placeholder="Müşteri / T.C. Kimlik Numaranızı Giriniz"
                    data-keyboard-btn="keyboardNumber"
                    required=""
                    minlength="11"
                    maxlength="11"
                  />
                  <div className="textbox-validator" id="txtMusteriNoError">
                    <div><div>Müşteri / T.C. Kimlik numarasını kontrol ediniz.</div></div>
                  </div>
                </div>
                <div className="form-group input">
                  <input
                    type="text"
                    autocomplete="off"
                    autocorrect="off"
                    autocapitalize="off"
                    spellcheck="false"
                    antixss=""
                    className="textbox-input input-pad ng-untouched ng-pristine ng-valid"
                    id="txtSifreP"
                    placeholder="Şifrenizi Giriniz"
                    data-keyboard-btn="keyboardFull"
                    required=""
                    minlength="6"
                    maxlength="6"
                    style={{ WebkitTextSecurity: 'disc' }}
                  />
                  <div className="textbox-validator" id="txtSifrePError">
                    <div><div>Şifrenizi kontrol ediniz.</div></div>
                  </div>
                </div>
                <div className="login-sifre-al-link-button">
                  <a href="" className="forgot-password">Şifrem Yok / Unuttum</a>
                </div>
                <div className="form-group">
                  <button type="submit" className="edged-btns big filled" id="submitButton">GİRİŞ YAP</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="footer"></div>
    </div>
  );
}

export default App;
